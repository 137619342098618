import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

// const UserPage = lazy(()=>
//  import ('../app-views/home/index.js')
// )

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
    
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
      
        <Route path={`${APP_PREFIX_PATH}/userlist`} component={lazy(() => import(`./userList`))} />

        <Route path={`${APP_PREFIX_PATH}/userpage/:username`}  component={lazy(() => import(`./userPage/`))} /> 
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);