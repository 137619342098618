import { 
  DashboardOutlined,
  UserSwitchOutlined,
  UnorderedListOutlined,
  DotChartOutlined,
  FundOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

// const dashBoardNavTree = [{
//   key: 'home',
//   path: `${APP_PREFIX_PATH}/home`,
//   title: 'home',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: []
// },
// {
//   key: 'Clients',
//   path: ``,
//   title: 'Clients',
//   icon: UnorderedListOutlined,
//   breadcrumb: false,
//   submenu: [
//     {
//       key: 'userList',
//       path: `${APP_PREFIX_PATH}/userList`,
//       title: 'User List',
//       icon: UserSwitchOutlined,
//       breadcrumb: false,
//       submenu: []
//     }
//   ]
// }

// ]
const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'home',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'Home',
      icon: HomeOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-analytic',
      path: `${APP_PREFIX_PATH}/dashboards/analytic`,
      title: 'sidenav.dashboard.analytic',
      icon: DotChartOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-sales',
      path: `${APP_PREFIX_PATH}/dashboards/sales`,
      title: 'sidenav.dashboard.sales',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]


  //   key: 'Clients',
  //   path: ``,
  //   title: 'Clients',
  //   icon: UnorderedListOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: 'userList',
  //       path: `${APP_PREFIX_PATH}/userList`,
  //       title: 'User List',
  //       icon: UserSwitchOutlined,
  //       breadcrumb: false,
  //       submenu: []
  //     }
  //   ]
  // }
const extraNavTree = [
  {
    key: 'extra',
    path: `${APP_PREFIX_PATH}/pages`,
    title: 'sidenav.pages',
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'extra-pages',
        path: `${APP_PREFIX_PATH}/pages`,
        title: 'sidenav.pages',
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'extra-pages-profile',
            path: `${APP_PREFIX_PATH}/pages/profile`,
            title: 'sidenav.pages.profile',
            icon: '',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'extra-pages-clients',
            path:  `${APP_PREFIX_PATH}/userList`,
            title: 'sidenav.pages.userlist',
            icon: UserSwitchOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'extra-pages-invoice',
            path: `${APP_PREFIX_PATH}/pages/invoice`,
            title: 'sidenav.pages.invoice',
            icon: '',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'extra-pages-pricing',
            path: `${APP_PREFIX_PATH}/pages/pricing`,
            title: 'sidenav.pages.pricing',
            icon: '',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'extra-pages-faq',
            path: `${APP_PREFIX_PATH}/pages/faq`,
            title: 'sidenav.pages.faq',
            icon: '',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'extra-pages-setting',
            path: `${APP_PREFIX_PATH}/pages/setting`,
            title: 'sidenav.pages.setting',
            icon: '',
            breadcrumb: true,
            submenu: []
          }
        ]
      },
     
       
    
    ]
  }
]
const navigationConfig = [
  ...dashBoardNavTree,
  ...extraNavTree,
]

export default navigationConfig;
